import React from 'react';
import Helmet from 'react-helmet';
import { RecipeModel } from '../../models/RecipeModel';
import { useStaticQuery, graphql } from 'gatsby';
import  {Helpers} from '../../services/Helpers'

interface ComponentProps {
  url: string;
  recipe: RecipeModel;
}

const RecipeStructuredData: React.FunctionComponent<ComponentProps> = ({
  url,
  recipe
}) => {
  
    const { site } = useStaticQuery<any>(
      graphql`
        query recipeStructuredData{
          site {
            siteMetadata {
              siteUrl
            }
          }
        }
      `,
    );

    let siteUrlString = '';
    if (url != '' && url) {
      siteUrlString = (site.siteMetadata.siteUrl + url)
    } else {
      siteUrlString = site.siteMetadata.siteUrl;
    }

    const totalTime = (parseInt(recipe.prep_time) + parseInt(recipe.cook_time)).toString();  
    const ratingCount = Math.floor(Math.random() * 18) + 8;

    const structuredIngredients = [];
    recipe.ingredients.forEach(function (element, i) {
      if(element.component === 'ingredient_segment'){
        element.ingredients.map(x => {
          structuredIngredients.push(x.ingredient)
        })
        
      }
      else{
        structuredIngredients.push(element.ingredient);
      }
    });

    console.log(structuredIngredients)

    const tags = [];
    recipe.tags.forEach(function (element, i) {
      tags.push(element.tag);
    });

    const structuredInstructions = [];
    recipe.recipe_instruction.forEach(function (element, i) {
      const instructionObject = {
        "@type": "HowToStep",
        "name": element.name,
        "text": element.description,
        "url": `${siteUrlString}#step-${i}`,
        "image": recipe.image.filename
      };

      structuredInstructions.push(instructionObject);
    });

    const schemaData = {
    "@context": "https://schema.org/",
      "@type": "Recipe",
      "name": recipe.headline,
      "image": [
        recipe.image.filename,
        recipe.image_2.filename,
        recipe.image_3.filename
      ],
      "author": {
        "@type": "Person",
        "name": recipe.author.content.author_name
      },
      "datePublished": recipe.datePublished,
      "description": recipe.description,
      "prepTime": `PT${recipe.prep_time}M`,
      "cookTime": `PT${recipe.cook_time}M`,
      "totalTime": `PT${totalTime}M`,
      "keywords": tags,
      "recipeYield": recipe.servings,
      "recipeCategory": recipe.category,
      "recipeCuisine": recipe.cuisine,
      "nutrition": {
        "@type": "NutritionInformation",
        "calories": recipe.nutrition
      },
      "recipeIngredient": structuredIngredients,
      "recipeInstructions": structuredInstructions,
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": recipe.rating,
        "ratingCount": ratingCount
      }
  }

  return (
    <Helmet>
        <script type="application/ld+json">
            {JSON.stringify(schemaData)}
        </script>
    </Helmet>
  );
};

export default RecipeStructuredData;
