import React from 'react';
import { RecipeInstruction } from '../../models/RecipeModel';

interface ComponentProps {
  method: Array<RecipeInstruction>;
}

const Method: React.FunctionComponent<ComponentProps> = ({
  method
}) => {
    return (
        <>
            <h3 className="mb-3 mt-8">Fremgangsmåde</h3>
            <hr className="mt-1 mb-4" />
            <div className="px-0 py-0">
                {method.map((item: RecipeInstruction, index: number) =>
                    <div key={index} id={`step-${index + 1}`} className="px-1 py-1 flex mb-2">
                        <div className="flex headline theme-circle w-4/4">{index + 1}</div>
                        <div className="flex w-4/4">
                            <p className="text-gray-900 mt-0 ml-6">{item.description}</p>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
};

export default Method;