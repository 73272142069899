import React from 'react'
import Routes from '../../constants/Routes';
import { Helpers } from '../../services/Helpers';
import { Link } from 'gatsby';

interface Props {
    tags: Array<string>;
}

const TagsComponent: React.FunctionComponent<Props> = ({ tags }) => {
    return (
        <>
            <h3 className="inline-flex mt-8" >Tags</h3>
            <hr className="mt-1 mb-4" />
            <div className="mb-2">
                {tags.map( (tag, index) => {
                    return(
                        <Link key={index} className="inline-block pr-4 pl-4 pt-2 pb-2 rounded mr-2 mb-2 tag" to={Routes.TAG + Helpers.sanitizeUrl(tag) + "/"} title={tag}>
                            {tag}
                        </Link>
                    );
                })}
            </div>
        </>
    );
}

export default TagsComponent;