import React from 'react';
import { Ingredient } from '../../models/RecipeModel';

interface ComponentProps {
  text: string;
}

const TipsAndTricks: React.FunctionComponent<ComponentProps> = ({
  text
}) => {
    return (
        <>
            <h3 className="mb-3 mt-8">Tips og Tricks</h3>
            <hr className="mt-1 mb-4" />
            <p dangerouslySetInnerHTML={{__html:text}}></p>
        </>
    )
};

export default TipsAndTricks;