import React from 'react';
import { RecipeModel } from '../../models/RecipeModel'
import RecipeCard from '../cards/RecipeCard'
import Routes from '../../constants/Routes'
import { StoryblokModel, AllStoryblokEntry } from '../../models/StoryblokModel'

interface ComponentProps {
    similarRecipes : AllStoryblokEntry
    currentRecipeHeadline: string
}


const SimilarRecipes : React.FunctionComponent<ComponentProps> = ({similarRecipes, currentRecipeHeadline}) => {
    return (
        <div className="mt-12 mb-12">
                    <h3 className="inline-flex mt-8">Lignende opskrifter</h3>
                    <hr className="mt-1 mb-4" />
                    <div className="grid grid-cols-1 md:grid-cols-2">
                        {similarRecipes.edges.slice(1, 5).map((item: StoryblokModel, index: number) =>
                        {
                            const similarRecipe : RecipeModel = JSON.parse(item.node.content)

                            if(currentRecipeHeadline !== similarRecipe.headline){
                                return(
                                    <div key={index}>
                                        <RecipeCard 
                                            title={similarRecipe.headline}
                                            image={similarRecipe.image.filename}
                                            imageAlt={similarRecipe.image.name || similarRecipe.image.filename}
                                            authorImage={similarRecipe.author.content.author_image[0].filename}
                                            authorName={similarRecipe.author.content.author_name}
                                            authorInstagramLink={similarRecipe.author.content.author_instagram_url}
                                            link={`/${Routes.RECIPEPREFIX}/${item.node.slug}/`}
                                            author_slug={similarRecipe.author.slug}
                                        />
                                    </div>
                                    )
                            }
                        }
                            
                        )}
                    </div>
                </div>
    )
}

export default SimilarRecipes;