import React from 'react';
import { RecipeModel } from '../../models/RecipeModel';
import StoryBlokImageComponent from '../images/imagecomponents/StoryBlokImageComponent';

interface ComponentProps {
  recipe: RecipeModel;
}

const ExtraImages: React.FunctionComponent<ComponentProps> = ({
  recipe
}) => {
    return (
        <>
            {recipe.image_2.filename !== '' && (
                <StoryBlokImageComponent src={recipe.image_2.filename} alt={recipe.image_2.name} dimensions="900x1200" className="mt-6 lg:mt-12" />
            )}
            {recipe.image_3.filename !== '' && (
                <StoryBlokImageComponent src={recipe.image_3.filename} alt={recipe.image_3.name} dimensions="900x1200" className="mt-6 lg:mt-12" />
            )}
        </>
    )
};

export default ExtraImages;