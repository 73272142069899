import React from "react"
import RecipeComponent from "../components/recipe/RecipeComponent"
import { graphql } from "gatsby"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import Announcement from "react-popup-announcement"
import CompetitionImage from "../images/hellofresh.jpeg"

interface ComponentProps {
  path: any
  location: any
  data: any
}

interface ComponentState {
  story: any
  tagsRegex: string
}

class StoryblokTemplate extends React.Component<
  ComponentProps,
  ComponentState
> {
  static getDerivedStateFromProps(props, state) {
    if (state.story.uuid === props.pageContext.story.uuid) {
      return null
    }

    return StoryblokTemplate.prepareStory(props)
  }

  static prepareStory(props) {
    const story = Object.assign({}, props.pageContext.story)
    story.content = JSON.parse(story.content)

    return { story }
  }

  constructor(props) {
    super(props)

    this.state = {
      story: StoryblokTemplate.prepareStory(props),
      tagsRegex: props.pageContext.tagsRegex,
    }
  }

  render() {
    let content = this.state.story.content

    function bannerClick() {
      window.open(
        "https://track.adtraction.com/t/t?a=1498475325&as=1696616625&t=2&tk=1",
        "_blank"
      )

      trackCustomEvent({
        category: "Banner",
        action: "Click",
      })
    }

    return (
      <>
        <RecipeComponent
          similarRecipes={this.props.data.allStoryblokEntry}
          recipe={content}
          path={this.props.location.pathname}
        />
        <Announcement
          title="Smag på HelloFresh med 2x20% rabat!"
          subtitle="Bestil en madkasse fra HelloFresh og gør den hverdag mere simpel. Indlægget indeholder reklame igennem affiliate links."
          imageSource={CompetitionImage}
          daysToLive={3}
          secondsBeforeBannerShows={5}
          closeIconSize={20}
          buttonText="Klik her"
          onBannerClick={bannerClick}
        />
      </>
    )
  }
}

export const query = graphql`
  query QueryRecipeWithTags($tagsRegex: String) {
    allStoryblokEntry(
      filter: {
        field_component: { eq: "Post" }
        content: { regex: $tagsRegex }
      }
    ) {
      edges {
        node {
          id
          name
          created_at
          uuid
          slug
          full_slug
          content
        }
      }
    }
  }
`

export default StoryblokTemplate
