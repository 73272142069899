import React from 'react';
import { Ingredient, IngredientSegment } from '../../models/RecipeModel';
import  {Helpers} from '../../services/Helpers'

interface ComponentProps {
  ingredients: Array<Ingredient> | IngredientSegment;
}

const Ingredients: React.FunctionComponent<ComponentProps> = ({
  ingredients
}) => {
    return (
        <>
        <h3 className="mb-3 mt-8">Ingredienser</h3>
        <hr className="mt-1 mb-4" />
            <div className="px-0 py-0">

                {ingredients.map((item: Ingredient | IngredientSegment, index: number) => {
                        if(Helpers.hasOwnProperty(item,'segment_name') == false){
                            return(
                                <div key={index+200}className={`px-1 py-1 ${(index % 2 === 0 ? 'rounded light-theme-background' : '')}`}>
                                    <p className="inline-block text-gray-800 ml-2 mt-1 mb-1">{item.ingredient}</p>
                                </div>
                            )
                        }
                })}

                {ingredients.map((item: Ingredient | IngredientSegment, index: number) => {
                    if(Helpers.hasOwnProperty(item,'segment_name') == true){
                        return(
                        <div key={index+100}>
                            <h5>{item.segment_name}</h5>
                            {item.ingredients.map((item: Ingredient, index: number) => {
                                return(
                                    <div key={index+200}className={`px-1 py-1 ${(index % 2 === 0 ? 'rounded light-theme-background' : '')}`}>
                                        <p className="inline-block text-gray-800 ml-2 mt-1 mb-1">{item.ingredient}</p>
                                    </div>
                                )                            
                            })}
                        </div>
                        )
                    }
                })}
            </div>
        </>
    )
};

export default Ingredients;