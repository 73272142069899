import React from 'react';

interface ComponentProps {
  category: string;
  author: string;
  author_url: string;
}

const Category: React.FunctionComponent<ComponentProps> = ({
  category,
  author,
  author_url
}) => {
    return (
        <div>
          <p className="inline-block text-sm mr-4 mt-0 uppercase">{category}</p>
          <span className="inline-block text-lg dark-theme-color mt-0">•</span>
          <a href={`https://vegetar.dk/chef/${author_url}/`} className="inline-block text-sm text-gray-800 opacity-80 ml-4 mt-0 font-sans font-normal leading-relaxed">{author}</a>
        </div>
    )
};

export default Category;