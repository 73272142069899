import React from 'react';
import Layout from '../layout/Layout';
import MetaData from '../seo/MetaData';
import Container from '../layout/Container';
import Category from './Category';
import SocialSharing from './SocialSharing';
import Ingredients from './Ingredients';
import Method from './Method';
import { RecipeModel } from '../../models/RecipeModel';
import TipsAndTricks from './TipsAndTricks';
import ExtraImages from './ExtraImages';
import TagsComponent from '../tags/TagsComponent';
import RecipeStructuredData from '../seo/RecipeStructuredData';
import StoryBlokImageComponent from '../images/imagecomponents/StoryBlokImageComponent';
import { AllStoryblokEntry } from '../../models/StoryblokModel';
import SimilarRecipes from './SimilarRecipes';
import { Provider } from "@lyket/react";
import { LikeButton } from "@lyket/react";
import Video from '../VideoComponent';

interface ComponentProps {
  recipe: RecipeModel;
  similarRecipes: AllStoryblokEntry
  path: string;
}

const RecipeComponent: React.FunctionComponent<ComponentProps> = ({
  similarRecipes,
  recipe,
  path
}) => {

    console.log(recipe)
    
    const tags = recipe.tags.map( tag => tag.tag.toLowerCase());

    return (
        <Provider apiKey={"c538be1c6cd05afeca378cc6d7129c"}>
            <Layout>
                <MetaData 
                    pageUrl={path}
                    title={recipe.headline}
                    thumbnailUrl={recipe.image.filename}
                    description={recipe.description}
                    imageAlt={recipe.image.name}
                />
                <RecipeStructuredData 
                    url={path}
                    recipe={recipe} 
                />
                <div className="bg-white">
                    <Container className="pt-4 text-center">
                    <h1>{recipe.headline}</h1>
                    <hr className="w-48 text-center mx-auto mb-2 border-2" />
                    <Category 
                        category={recipe.category}
                        author={recipe.author.content.author_name}
                        author_url={recipe.author.slug}
                    />
                    <SocialSharing
                        link={path}
                    />
                    <StoryBlokImageComponent src={recipe.image.filename} alt={recipe.image.name} dimensions="1200x900" />
                    </Container>
                    <Container>
                        <p>{recipe.description}</p>
                        <Ingredients 
                            ingredients={recipe.ingredients}
                        />
                        <Method 
                            method={recipe.recipe_instruction}
                        />
                        <TipsAndTricks 
                            text={recipe.tips_tricks} 
                        />
                        <br></br>                        
                        {recipe.videos instanceof Array ? recipe.videos.map( vid => 
                            <div key={vid.title}>
                                <Video videoSrcURL={vid.url} videoTitle={vid.title}/>
                                <br></br>
                            </div>
                        ) : console.log("no available videos")}
                        <ExtraImages recipe={recipe} />
                        <TagsComponent tags={tags}/>
                        <SimilarRecipes similarRecipes={similarRecipes} currentRecipeHeadline={recipe.headline}/>
                    </Container>
                </div>
            </Layout>
        </Provider>
    )
};

export default RecipeComponent;