import React from "react"

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className="video" style={video}>
    <iframe
      style={responsiveIframe}
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
)

const video = {
        position: "relative",
        overflow: "hidden",
        width: "100%",
        paddingTop: "56.25%", /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
} as React.CSSProperties; 
      
      /* Then style the iframe to fit in the container div with full height and width */
const responsiveIframe = {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%"
} as React.CSSProperties;
    
export default Video