import React from 'react'
import Colors from '../../constants/Colors';
import { FaFacebook, FaTwitter, FaPinterest } from 'react-icons/fa';
import { graphql, useStaticQuery } from 'gatsby';

interface Props {
    link: string;
}

const SocialSharing: React.FunctionComponent<Props> = ({ link }) => {

    const { site } = useStaticQuery<any>(
        graphql`
          query socialSharingQuery {
            site {
              siteMetadata {
                siteUrl
              }
            }
          }
        `,
      );

    const { siteUrl } = site.siteMetadata;

    const facebookLink: string = `https://www.facebook.com/sharer/sharer.php?u=${siteUrl}${link}`;
    const twitterLink: string = `https://twitter.com/intent/tweet?&url=${siteUrl}${link}`;
    const pinterestLink: string = `https://pinterest.com/pin/create/button/?url=${siteUrl}${link}`;

    return (
        <div className="mb-2">
            <div className="inline-block text-center px-0 py-1">
                <a href={facebookLink} target="_blank" rel="nofollow">
                    <FaFacebook
                        style={icon}
                        size={25}
                    />
                </a>
            </div>
            <div className="inline-block text-center px-0 py-1">
                <a href={twitterLink} target="_blank" rel="nofollow">
                    <FaTwitter
                        style={icon}
                        size={25}
                    />
                </a>
            </div>
            <div className="inline-block text-center px-0 py-1">
                <a href={pinterestLink} target="_blank" rel="nofollow">
                    <FaPinterest
                        style={icon}
                        size={25}
                    />
                </a>
            </div>
        </div>
    );
}

const icon = {
    color: Colors.green,
    opacity: 0.8,
    marginRight: 15,
    cursor: 'pointer'
} as React.CSSProperties;

export default SocialSharing;